import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Button } from '@/components/ui'
import { useStore } from '@/store'
import { copyToClipboard, formatEncounterDate } from '@/util'
import { toast } from 'sonner'
import { useGeneratePdf, useRegenerateNarrative } from '@/hooks/api'
import { CustomTemplateClinicalMap } from './CustomTemplateClinicalMap'
import { ReportIssue } from '../ReportIssue'
import { SocketApi } from '@/services/SocketApi'
import { NARRATIVE_TAB_CONFIGS } from '@/utils/constants'
import posthog from 'posthog-js'
import { SendToEhrButton } from '../ui/sendtoehr-button'

const socket = new SocketApi()

export const CustomTemplateSidebar: React.FC = () => {
  const { view, customTemplate, editedFinalNote, encounter, setIsPending, activeCustomTemplate } = useStore(state => ({
    view: state.view,
    customTemplate: state.customTemplate,
    encounter: state.encounter,
    setIsPending: state.setIsPending,
    editedFinalNote: state.customTemplate?.editedNotes?.templateNote || '',
    activeCustomTemplate: state.activeCustomTemplate
  }))

  const [content, setContent] = useState<string>('')
  const generatePdf = useGeneratePdf()
  const { mutate: regenerateNarrative } = useRegenerateNarrative()
  const { title, contentKey, fileName, showClinicalMap } = NARRATIVE_TAB_CONFIGS[view] || {}

  useEffect(() => {
    if (view === 'CustomTemplateEdit') {
      setContent(editedFinalNote || customTemplate?.finalNote || '')
    } else {
      const editedContent = customTemplate?.editedNotes[contentKey] || customTemplate[contentKey] || ''
      setContent(editedContent)
    }
  }, [view, customTemplate, contentKey, editedFinalNote])

  const getFormattedNoteText = (preserveFormatting: boolean = false) => {
    if (!content) {
      toast.error(`No ${title?.toLowerCase()} to process`)
      return ''
    }
    return preserveFormatting ? `${title}<br/><br/>${content.replace(/\n/g, '<br/>')}` : `${title}\n\n${content}`
  }

  const handleCopy = () => {
    if (content) {
      const text = getFormattedNoteText()
      copyToClipboard(text)
      toast.success(`${title} copied to clipboard`)
      posthog.capture('user_copied_custom_template_notes', {
        bolaSessionId: encounter?.id,
        bolaCustomTemplate: customTemplate.currentCustomTemplateId
      })
    } else {
      toast.error(`No ${title.toLowerCase()} to copy`)
    }
  }

  const handleDownload = () => {
    if (content) {
      const file = `${formatEncounterDate(encounter?.createdAt ?? new Date())} ${fileName}`

      const promise = generatePdf.mutateAsync({
        fileName: file,
        text: content
      })
      posthog.capture('user_clicked_download_custom_template_notes', {
        bolaSessionId: encounter?.id,
        bolaCustomTemplate: customTemplate.currentCustomTemplateId
      })
      toast.promise(promise, {
        loading: `Generating ${title.toLowerCase()} PDF...`,
        success: `Downloaded ${title.toLowerCase()}`,
        error: `Failed to generate ${title.toLowerCase()}. Please try again.`
      })
    } else {
      toast.error(`No ${title.toLowerCase()} to download`)
    }
  }

  const handleEditClick = () => {
    const variables =
      view === 'CustomTemplate' ? activeCustomTemplate?.variables : customTemplate?.generatedTemplate?.variables

    if (variables) {
      const unfilledRequiredVariables = variables?.filter(({ required, name, defaultValue }) => {
        const filledValue = customTemplate?.filledValues?.[name]?.trim()
        return required && (!filledValue || filledValue === '') && !defaultValue
      })

      if (unfilledRequiredVariables?.length > 0) {
        const unfilledVariableNames = unfilledRequiredVariables?.map(({ displayName }) => displayName).join(', ')
        toast.error(`The following required fields are not filled: ${unfilledVariableNames}`)
        return
      }
    }

    const finalNote = customTemplate?.finalNote

    if (finalNote) {
      const additionalNotes = customTemplate?.filledValues?.['ADDITIONAL_NOTES'] || ''
      const noteWithAdditionalNotes = additionalNotes
        ? `${finalNote}\n\nAdditional Notes: ${additionalNotes}`
        : finalNote

      setIsPending(true)
      socket.generateCustomTemplateNote(noteWithAdditionalNotes)
      Sentry.addBreadcrumb({
        message: `Final note: ${noteWithAdditionalNotes}`,
        level: 'info',
        category: 'info'
      })
      toast.success('Preparing clinical note for editing')
      posthog.capture('user_edited_and_finalized_custom_template_notes', {
        bolaSessionId: encounter?.id,
        bolaCustomTemplate: customTemplate.currentCustomTemplateId
      })
    } else {
      toast.error('No clinical note available to edit')
    }
  }

  const handleRegenerateNarrative = () => {
    regenerateNarrative({
      finalNote: customTemplate?.finalNote || '',
      editedFinalNote: customTemplate?.editedNotes?.templateNote
    })
  }

  return (
    <div className="flex h-full max-w-full flex-col overflow-hidden p-4">
      {showClinicalMap ? (
        <>
          <div className="max-w-full flex-1 overflow-y-auto overflow-x-hidden">
            <CustomTemplateClinicalMap />
          </div>
          <div className="mt-4 flex flex-col gap-4">
            <Button size="lg" onClick={handleEditClick}>
              Edit and Finalize Clinical Note
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4 flex flex-col gap-4">
            <Button size="lg" onClick={handleCopy} disabled={!content}>
              Copy {title}
            </Button>
            <SendToEhrButton noteText={getFormattedNoteText(true)} />
            <Button size="lg" variant="outline" onClick={handleDownload} disabled={!content}>
              Download {title} as PDF
            </Button>
            <Button size="lg" variant="outline" onClick={handleRegenerateNarrative} disabled={!content}>
              Regenerate {title}
            </Button>
          </div>
        </>
      )}
      <div className="mt-4">
        <ReportIssue />
      </div>
    </div>
  )
}

export default CustomTemplateSidebar
