import React from 'react'
import { Button } from '@/components/ui'
import { toast } from 'sonner'
import posthog from 'posthog-js'
import { determineTrustedOrigin } from '@/utils/helper'
import { useStore } from '@/store'
import { WindowEvents } from '@/utils/events.enum'

type SendToEhrButtonProps = {
  noteText: string
  disabled?: boolean
}

export const SendToEhrButton: React.FC<SendToEhrButtonProps> = ({ noteText, disabled }) => {
  const { ehr } = useStore(state => ({
    ehr: state.ehr
  }))
  const trustedOrigin = determineTrustedOrigin()

  const isIframe = window.parent !== window

  const capitalizedEhr = ehr.charAt(0).toUpperCase() + ehr.slice(1)

  if (!isIframe) {
    return null
  }

  const handleSendToEhr = () => {
    if (!noteText) {
      toast.error('No content to send')
      return
    }

    try {
      window.parent.postMessage(
        { action: WindowEvents.NOTES_COPIED, data: { value: noteText, message: `Note sent to ${capitalizedEhr}` } },
        trustedOrigin
      )
      toast.success(`Sent to ${capitalizedEhr}`)
      posthog.capture(`user_sent_to_${capitalizedEhr}`)
    } catch (error) {
      toast.error(`Failed to send note to ${capitalizedEhr}`)
    }
  }

  const getIcon = () => {
    switch (ehr) {
      case 'curve':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.86712 22.86712" width="22.86712" height="22.86712">
            <path
              fill="#f16123"
              d="M22.863665,13.670347a5.325,5.325,0,0,1-8.96121,3.69472l-0.02176-0.02094-1.02042-1.02061a0.46456,0.46456,0,0,1,0-0.65729l0.74634-0.74655a0.49733,0.49733,0,0,1,0.68637,0l0.99377,0.99377a3.31922,3.31922,0,1,0,1.74987-5.71947,3.418,3.418,0,0,0-1.88893,0.98117l-2.55965,2.55965-0.10369,0.11467-3.3929996,3.3928a5.32719,5.32719,0,0,1-9.08644996-3.996,5.32482,5.32482,0,0,1,8.95958996-3.6583202l0.022,0.02114,1.0169396,1.0169402a0.46972,0.46972,0,0,1,2e-4,0.66421l-0.7249996,0.7248a0.523,0.523,0,0,1-0.72256,0l-0.97568-0.97548a3.31922,3.31922,0,1,0-1.75012,5.71945,3.41849,3.41849,0,0,0,1.88913-0.98117l2.5596796-2.55968,0.10369-0.11466,3.32958-3.3295802a5.45248,5.45248,0,0,1,3.22592-1.58984,5.33148,5.33148,0,0,1,5.92542,5.4862702M6.6256454,7.5444668h1.059a0.45447,0.45447,0,0,0,0.455-0.42227,3.32008,3.32008,0,0,1,6.6172796-0.01443,0.47223,0.47223,0,0,0,0.47147,0.4367h1.00943a0.526,0.526,0,0,0,0.38445-0.16793,0.51907,0.51907,0,0,0,0.137-0.3905502,5.32582,5.32582,0,0,0-10.6233996,0.03517,0.49079,0.49079,0,0,0,0.48977,0.52331"
            />
          </svg>
        )
      default:
        return <span className="mr-2">🔍</span>
    }
  }

  return (
    <Button size="lg" variant="outline" onClick={handleSendToEhr} disabled={disabled} className="flex items-center">
      Send to {capitalizedEhr}&nbsp;&nbsp;
      {getIcon()}
    </Button>
  )
}
