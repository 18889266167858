import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '@/store'
import { Button, Input, Label } from '@/components/ui'
import bolaLogo from '/assets/bola-logo.svg'
import { CustomLinks } from '@/Links'
import * as Sentry from '@sentry/react'
import { WindowEvents } from '@/utils/events.enum'
import { EHROrigin } from '@/utils/domain.enum'

export const Authentication = () => {
  const navigate = useNavigate()

  const [licenseKey, setLicenseKey] = useState('')
  const [error, setError] = useState(false)
  const [buttonState, setButtonState] = useState({ clicked: false })
  const allowedOrigins: string[] = Object.values(EHROrigin)

  const { validateLicenseKey, storeAuthentication, requestErrors, requestSuccess, isAuthenticated } = useStore(
    state => ({
      validateLicenseKey: state.validateLicenseKey,
      storeAuthentication: state.storeAuthentication,
      requestErrors: state.requestErrors,
      requestSuccess: state.requestSuccess,
      isAuthenticated: state.isAuthenticated
    })
  )

  useEffect(() => {
    window.parent.postMessage({ type: WindowEvents.IFRAME_READY }, '*')

    const handleMessage = async (event: MessageEvent) => {
      if (!allowedOrigins.includes(event.origin)) {
        Sentry.addBreadcrumb({ message: `Untrusted origin: ${event.origin}` })
        return
      }

      const { action, data } = event.data || {}

      if (!action || !data) {
        Sentry.addBreadcrumb({ message: `Invalid message structure: ${JSON.stringify(event.data)}` })
        return
      }

      switch (action) {
        case WindowEvents.ACTIVATE_LICENSE_KEY: {
          setLicenseKey(data.licenseKey)
          await validateAndStoreLicense(data.licenseKey)
          navigate('/welcome')
          break
        }

        default:
          Sentry.addBreadcrumb({ message: `Unknown action: ${action}` })
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  useEffect(() => {
    if (requestErrors) {
      setError(true)
    } else if (requestSuccess) {
      setError(false)
      navigate('/terms')
    }
  }, [requestErrors, requestSuccess, navigate])

  const validateAndStoreLicense = async (key: string) => {
    const isValid = await validateLicenseKey(key)
    if (isValid) {
      storeAuthentication({ license_key: key })
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setButtonState({ clicked: !buttonState.clicked })
    await validateAndStoreLicense(licenseKey)
  }

  const handleClickOnLink = (link: string) => {
    switch (link) {
      case 'demo':
        window.open(CustomLinks.bolaDemo)
        break
      case 'contactUs':
        window.open(CustomLinks.bolaContactUs)
        break
      default:
        window.open(CustomLinks.bolaDemo)
        break
    }
  }

  return (
    <section className="m-44 min-h-screen">
      <header className="h-50 flex">
        <img src={bolaLogo} className="h-20" alt="Bola Logo" />
      </header>
      <main className="mt-28">
        <article>
          <h2 className="text-4xl font-bold">Activating your AI Scribe</h2>
        </article>
        <article>
          <p className="mt-5 text-2xl">
            Please input the license key found in your welcome email in the field below to complete activation
          </p>
        </article>

        <hr className="mx-auto mt-20 bg-black" />

        <form className="mt-16" onSubmit={handleSubmit}>
          <div className="flex max-w-lg items-center space-x-2">
            <Input
              type="text"
              value={licenseKey}
              onChange={e => setLicenseKey(e.target.value)}
              placeholder="License Key"
              className="h-12 w-11/12"
            />
            <Button
              className={`grow-1 ml-5 h-12 w-32 rounded-md text-white ${licenseKey === '' ? 'bg-gray-500' : 'bg-primary'}`}
              disabled={licenseKey === ''}
            >
              Activate
            </Button>
          </div>

          {error && <div className="text-red-500">{requestErrors?.message}</div>}
          {isAuthenticated && <div className="text-green-500">Successfully authenticated!</div>}
        </form>

        <article className="mt-12 text-center">
          Don’t have a license key?
          <Button
            className="mt-9"
            variant="link"
            onClick={() => {
              handleClickOnLink('demo')
            }}
          >
            Sign up for a demo today
          </Button>
          <Button
            className="mt-9"
            variant="link"
            onClick={() => {
              handleClickOnLink('contactUs')
            }}
          >
            To enable AI Scribe for your license key, Contact Us
          </Button>
        </article>
      </main>
    </section>
  )
}
