import React, { useEffect, useState } from 'react'
import { useStore } from '@/store'
import { SocketApi, SocketApiListenEvents } from '@/services/SocketApi'
import { Variable } from '../templates/ui/Variable'
import * as Sentry from '@sentry/react'
import './CustomTemplate.css'

const socket = new SocketApi()

const applyInitialPlaceholderHighlight = templateData => {
  if (!templateData) return []

  const { note = '', variables = [] } = templateData

  const parts: (string | JSX.Element)[] = []
  const variableDefaults = Object.fromEntries(
    variables.map((variable: any) => [variable.name, variable.defaultValue || null])
  )

  note.split(/(\[\[.*?\]\])/).forEach(part => {
    const match = part.match(/\[\[(.*?)\]\]/)
    if (match) {
      parts.push(
        <Variable key={match[1]} value={variableDefaults[match[1]]}>
          {`${match[1]}`}
        </Variable>
      )
    } else {
      parts.push(part)
    }
  })

  return parts
}

const applyValuesToTemplate = (currentDisplayedNote, newValues, variables) => {
  const variableDefaults = Object.fromEntries(
    variables.map((variable: any) => [variable.name, variable.defaultValue || null])
  )

  return currentDisplayedNote.map(part => {
    if (typeof part !== 'string') {
      const variableName = part.props.children.replace(/\[\[|\]\]/g, '')
      let value = newValues[variableName] || variableDefaults[variableName]

      if (value && typeof value === 'object') {
        value = JSON.stringify(value)
      }

      if (value) {
        return (
          <Variable key={variableName} value={value}>
            {value}
          </Variable>
        )
      }
    }
    return part
  })
}

const convertToPlainText = (note: (string | JSX.Element)[]) => {
  return note.map(part => (typeof part === 'string' ? part : part.props?.children || '')).join('')
}

export const CustomTemplate: React.FC = () => {
  const { customTemplate, activeCustomTemplate, setFilledNote, setFinalNote, updateEncounter } = useStore(state => ({
    customTemplate: state.customTemplate,
    activeCustomTemplate: state.activeCustomTemplate,
    setFilledNote: state.setFilledValues,
    setFinalNote: state.setFinalNote,
    updateEncounter: state.updateEncounter
  }))

  const [displayedNote, setDisplayedNote] = useState<(string | JSX.Element)[]>([])

  useEffect(() => {
    const initialNote = applyInitialPlaceholderHighlight(activeCustomTemplate)

    if (customTemplate?.filledValues) {
      const updatedNote = applyValuesToTemplate(
        initialNote,
        customTemplate.filledValues,
        activeCustomTemplate?.variables || []
      )
      setDisplayedNote(updatedNote)
    } else {
      setDisplayedNote(initialNote)
      setFilledNote({})
      setFinalNote(convertToPlainText(initialNote))
    }
  }, [activeCustomTemplate, setFilledNote, setFinalNote, customTemplate])

  useEffect(() => {
    if (!activeCustomTemplate || !activeCustomTemplate.note) return

    const handleRealTimeTranscript = (data: any) => {
      if (data.final && data.transcript?.length > 5) {
        const rawTranscript = `${useStore.getState().encounter?.rawTranscript || ''} ${data.transcript}`.trim()
        console.log('RAW Transcript: ', rawTranscript)
        updateEncounter({ rawTranscript })

        if (socket.isConnected()) {
          const variableNames = activeCustomTemplate.variables.map(variable => variable.name)

          if (!variableNames || variableNames.length === 0) {
            return
          }
          const stringifiedVariables = JSON.stringify(variableNames, null, 2)

          socket.generateCustomTemplate(stringifiedVariables, rawTranscript)
        } else {
          Sentry.addBreadcrumb({ message: 'Socket not connected, cannot generate template.' })
        }
      }
    }

    const handleCustomTemplateGenerated = (data: any) => {
      try {
        console.log('GENERATED DATA: ', JSON.stringify(data, null, 2))
        const updatedNote = applyValuesToTemplate(
          displayedNote,
          data.customTemplateResult,
          activeCustomTemplate?.variables || []
        )

        setDisplayedNote(updatedNote)

        const filledValues = {
          ...(customTemplate.filledValues || {}),
          ...data.customTemplateResult
        }

        const plainTextNote = convertToPlainText(updatedNote)
        setFinalNote(plainTextNote)

        setFilledNote(filledValues)
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    socket.onUniqueListener(SocketApiListenEvents.REALTIME_TRANSCRIPT, handleRealTimeTranscript)
    socket.onUniqueListener(SocketApiListenEvents.CUSTOM_TEMPLATE_GENERATED, handleCustomTemplateGenerated)
  }, [activeCustomTemplate, displayedNote, setFilledNote, setFinalNote, updateEncounter, socket.getSocket()])

  if (!displayedNote.length) return <div>No custom template selected</div>

  return (
    <div className="mt-5 rounded-lg p-4 shadow-md">
      <div className="custom-template-note">{displayedNote}</div>
    </div>
  )
}

export default CustomTemplate
