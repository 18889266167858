import posthog from 'posthog-js'
import { useState } from 'react'

import { Button } from '@/components/ui'
import { useStore } from '@/store'
import { copyToClipboard } from '@/util'
import { SendToEhrButton } from './ui/sendtoehr-button'

export const EditSidebar = () => {
  const encounter = useStore(state => state.encounter)
  const licenseKey = useStore(state => state.licenseKey)
  const [isCopyingTranscript, setIsCopyingTranscript] = useState(false)

  const isIframe = window.parent !== window

  const getFormattedNote = (preserveFormatting = false) => {
    if (!encounter?.templateRaw) return ''
    return preserveFormatting ? encounter?.templateRaw.replace(/\n/g, '<br/>') : encounter?.templateRaw
  }

  const onCopyTemplateClick = () => {
    posthog.capture('user_copied_template_raw')
    void copyToClipboard(encounter?.templateRaw || '')
    setIsCopyingTranscript(true)
    setTimeout(() => setIsCopyingTranscript(false), 4000)
  }

  return (
    <div className="p-8">
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col items-stretch gap-4">
          <Button size="lg" className="mt-2 w-full" onClick={onCopyTemplateClick}>
            {isCopyingTranscript ? 'Copied' : 'Copy Note'}
          </Button>
          <SendToEhrButton noteText={getFormattedNote(true)} />
        </div>
      </div>
    </div>
  )
}
