import { useStore } from '@/store'
import { EhrDomain, EHROrigin } from './domain.enum'

export const determineTrustedOrigin = () => {
  const { ehr } = useStore(state => ({
    ehr: state.ehr
  }))
  switch (ehr) {
    case EhrDomain.CURVE:
      return EHROrigin.CURVE
    default:
      return ''
  }
}

export const getEhr = (input: string): string => {
  const normalizedInput = input.toLowerCase()
  switch (true) {
    case normalizedInput.includes(EhrDomain.CURVE):
      return EhrDomain.CURVE
    default:
      return EhrDomain.DEFAULT
  }
}
