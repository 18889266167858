import React from 'react'
import { DotFilledIcon, CheckIcon, StarIcon, StarFilledIcon } from '@radix-ui/react-icons'
import { VariableListProps } from '@/interfaces/interface'
import { useStore } from '@/store'

const VariableList: React.FC<VariableListProps> = ({ variables, showOptions, isVariableFilled }) => {
  const { setGeneratedTemplate, setActiveCustomTemplate, customTemplate, activeCustomTemplate, view } = useStore(
    state => ({
      setGeneratedTemplate: state.setGeneratedTemplate,
      setActiveCustomTemplate: state.setActiveCustomTemplate,
      customTemplate: state.customTemplate,
      activeCustomTemplate: state.activeCustomTemplate,
      view: state.view
    })
  )

  const getVariableIcon = (variable: any): JSX.Element => {
    if (isVariableFilled(variable.name)) {
      return <CheckIcon className="size-5 text-[#5AE4C3]" />
    }
    if (variable.isStarIcon) {
      return <StarIcon className="size-5 text-[#E0C300]" />
    }
    return <DotFilledIcon className="size-5 text-[#050a17]" />
  }

  const getToggleIcon = (isRequired: boolean): JSX.Element => {
    return isRequired ? (
      <StarFilledIcon className="size-5 text-[#E0C300]" />
    ) : (
      <StarIcon className="size-5 text-[#6b7280]" />
    )
  }

  const handleToggleRequired = (variableName: string) => {
    const updateVariables = (variables, variableName: string) =>
      variables.map(variable =>
        variable.name === variableName ? { ...variable, required: !variable.required } : variable
      )

    if (view === 'CreateCustomTemplate') {
      const updatedVariables = updateVariables(variables, variableName)
      const { generatedTemplate } = customTemplate

      const updatedTemplate = {
        ...generatedTemplate,
        variables: updatedVariables,
        note: generatedTemplate?.note ?? null,
        type: generatedTemplate?.type ?? null,
        name: generatedTemplate?.name ?? null,
        createdAt: generatedTemplate?.createdAt ?? new Date(),
        updatedAt: generatedTemplate?.updatedAt ?? null,
        archivedAt: generatedTemplate?.archivedAt ?? null
      }

      setGeneratedTemplate(updatedTemplate)
    } else if (view === 'CustomTemplate' && activeCustomTemplate) {
      const updatedVariables = updateVariables(activeCustomTemplate.variables, variableName)

      const updatedCustomTemplate = {
        ...activeCustomTemplate,
        variables: updatedVariables
      }

      setActiveCustomTemplate(updatedCustomTemplate)
    }
  }

  if (variables.length === 0) {
    return (
      <div className="flex flex-col justify-center">
        <p className="mt-4 text-[15px] text-[#6b7280]">
          Please paste your custom template and click "Create Custom Template" to display the variables.
        </p>
      </div>
    )
  }

  return (
    <div className="max-h-[60vh] overflow-y-auto px-4 pb-4">
      <ul className="space-y-4">
        {variables.map((variable, index) => (
          <li key={index} className="text-[15px] text-[#1a1c23]">
            <div className="flex items-center gap-2">
              {getVariableIcon(variable)}
              <span>{variable.displayName}</span>
              <button className="ml-2" onClick={() => handleToggleRequired(variable.name)}>
                {getToggleIcon(variable.required)}
              </button>
            </div>
            {showOptions && variable.values && variable.values.length > 0 && (
              <ul className="ml-8 mt-1 space-y-1">
                {variable.values.map((value, idx) => (
                  <li key={`${variable.name}-${idx}`} className="flex items-center gap-2 text-[#6b7280]">
                    <DotFilledIcon className="size-4 text-[#E0C300]" />
                    <span>{value}</span>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default VariableList
